<template>
  <div class="home">
    <img style="padding-top: 20px; opacity: 0.80; max-width: 100%; min-width: 300px; height: auto;" src="../assets/2logo.png" />
    <h4 id="title" style="text-decoration: none;">Fine Art Reproduction<br><span style="color: rgba(242, 157, 75, 0.67); font-size:23px;">&</span><br>Archival Pigment Printing</h4>
    <div id="icons">
        <a href="mailto:thepaintedpixel@gmail.com"><img src="../assets/mail.svg" /></a>
        <a href="tel:303-233-2357"><img src="../assets/phone.svg" /></a>
        <a target="_blank" href="https://www.google.com/maps/place/7070+W+16th+Ave,+Lakewood,+CO+80214/@39.7413902,-105.0779915,17z/data=!3m1!4b1!4m6!3m5!1s0x876b8726cdefb487:0x9be3207a31c76919!8m2!3d39.7413861!4d-105.0754166!16s%2Fg%2F11c2h99xw7?entry=ttu"><img src="../assets/map-pin.svg" /></a>
        <a target="_blank" href="https://www.instagram.com/thepaintedpixel/?hl=en"><img src="../assets/instagram.svg" /></a> 
        <a style="display: none;" target="_blank" href="https://www.facebook.com/thepaintedpixel/"><img src="../assets/facebook.svg" /></a>
        <a style="display: none;" target="_blank" href="https://mobile.twitter.com/thepaintedpixel"><img src="../assets/twitter.svg" /></a>
    </div>
    <a target="_blank" style="
      background-color: transparent; 
      border: 1px solid #546476;
      color: #2c3e50; 
      height: 40px; 
      text-transform: uppercase;
      text-decoration: none;
      letter-spacing: 1px; 
      line-height: 38px; 
      padding: 0 10px; 
      border-radius: 8px; 
      font-weight: 500; 
      font-size: 14px; 
      cursor: pointer; display: 
      inline-block;"
      href="https://squareup.com/appointments/book/0ud6e8kdxdgwy1/ESPTFE637VPJ5/start">Book an Appointment
    </a>



    <h5 style="display: none; padding-top: 20px;">
    <a target="_blank" href="https://www.google.com/maps/place/7070+W+16th+Ave,+Lakewood,+CO+80214/@39.7413902,-105.0779915,17z/data=!3m1!4b1!4m6!3m5!1s0x876b8726cdefb487:0x9be3207a31c76919!8m2!3d39.7413861!4d-105.0754166!16s%2Fg%2F11c2h99xw7?entry=ttu">
    <img style="padding-bottom: 5px;" src="../assets/map-pin.svg" />
    <br/>7070 W 16th Avenue<br/>Lakewood, CO 80214<br/>
    </a>
    </h5>
    

  </div>
</template>

<style scoped>

a {
  color: #2c3e50;
  text-decoration: none;
  opacity: 0.7;
  display: inline;
  padding-left: 0px;
  }

a:hover{
  opacity: 1;
  }


h5{
  margin-top: -2px;
}

#icons a{
  margin-right: 33px;
}

#icons{
  margin-top: 10px;
  margin-right: -33px;
  padding-bottom: 15px;
}

#title{
  padding: 33px;
  margin-top: -16px;
  margin-bottom: -16px;
}

</style>
