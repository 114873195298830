


<template>

 <div id="nav">
    <span style="float: left;">
      <router-link to="/"><img src="../src/assets/nav-logo.png"></router-link>
    </span>
    <span class="nav-center" style="float: center;">
      <router-link to="/digitization">Digitization</router-link> |
      <router-link to="/printing">Printing</router-link> |
      <router-link to="/about">About</router-link>
    </span>
    <span style="visibility: hidden; float: right;">
      <a :href="'mailto:thepaintedpixel@gmail.com'"><img src="../src/assets/mail.png" /></a>
    </span>
  </div> 
  
 <!-- 
  <transition
    name="fade"
    mode="out-in"
    enter-active-class="animate__animated animate__fadeIn"
    leave-active-class="animate__animated animate__fadeOut"
  >
    <router-view />
  </transition>
-->

    <router-view v-slot="{ Component, route }">
      <Transition name="fade" mode="out-in">
        <div :key="route.name">  
          <component :is="Component"></component>
        </div>
      </Transition>
    </router-view>


  <br/>
</template>





<style>
html { 
        background: url('../src/assets/bg2.png') no-repeat center center fixed; 
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }
p {
  max-width: 600px;
  margin: auto;
}
.home, .digitization, .printing, .price-list, .about{
  padding-top: 33px;
}


.fade-enter-active,
.fade-leave-active {
  transition: 333ms linear;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}


#app {
  font-family: unit-rounded, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}






#nav {
  padding: 30px;
}

#nav a {
  color: #2c3e50;
  text-decoration: none;
  opacity: 0.8;
}

.nav-center a {
  padding: 10px;
  font-weight: 700;
  text-transform: uppercase;
}

#nav a:hover {
  opacity: 0.7;
}

#nav a.router-link-exact-active {
  color: #f29d4b;
  opacity: 0.85;
}

h1{
  font-weight: 700;
  font-size: 23px;
  text-transform: uppercase;
  opacity: 0.85; 
  line-height: 125%;
  letter-spacing: 1px;
  text-decoration: underline;
  text-decoration-color: rgba(242, 157, 75, 0.50);
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
}

h3{
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1.17em;
  letter-spacing: 1px;
  text-decoration: underline;
  text-decoration-color: rgba(242, 157, 75, 0.50);
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
}

h4{
  font-size: 15px;
  text-transform: uppercase;
  max-width: 400px;
  font-weight: 600;
  letter-spacing: 3px;
  line-height: 21px;
  padding: 13px;
  margin: 0 auto;
}

h5{
  font-size: 14px;
}

summary{
  cursor: pointer;
}


a, li, p, h3, h4, h5 {
  opacity: 0.85;
}

p{
  max-width: 400px;
  font-size: 14px;
}

li, a{
  font-size: 14px;
}






</style>



<script type="text/javascript">
/*
window.onload = function(){ 
               alert("We are moving to a new location and will reopen 1/2/2024. \nPlease email for details or schedule an appointment. \nHappy New Year!");
               }

*/
</script>




